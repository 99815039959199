<template>
  <main id="login" class="login-container">
    <router-link to="/" tabindex="0">
        <img
          src="../assets/images/ametros-learning-logo.svg"
          alt="Ametros Learning Logo"
          class="login-logo"
          aria-label="back to Login"
        />
      </router-link>
    <div class="reset-form mt-2">
      <div v-if="resetMailSent">
        <h1>Password reset link sent</h1>
        <p class="mt-4">
          Check your email for a link to reset your password. If it doesn’t
          appear within a few minutes, check your spam folder.
        </p>
        <div class="text-end mt-3">
          <button
            type="button"
            class="btn btn-ametros"
            @click="returnToLogin()"
            @keyup.enter="returnToLogin()"
            :disabled="email.length == 0"
            aria-label="Return to Login page"
          >
            Return to sign In
          </button>
        </div>
      </div>
      <div v-else>
        <form role="form" aria-label="reset password">
          <h1>Reset password</h1>
          <div class="form-group mt-4">
            <label>
              Enter your user account's verified email address and click Reset Password.
              We will send you a password reset email.
            </label>
            <input
              type="email"
              class="form-control mt-4"
              name="email"
              v-model="email"
              placeholder="Enter your email address"
              aria-label="Enter your email address"
            />
          </div>
          <div class="text-end">
            <button
              type="button"
              class="btn btn-ametros"
              @click="sendPasswordResetMail()"
              aria-label="Reset Password"
              :aria-disabled="resetPasswordDisabled"
            >
              Reset Password
            </button>
          </div>
        </form>
      </div>
    </div>
  </main>
</template>
<script>
import api from "../services/api";
import { utilFunctionService } from "@/utils/utils.service";

export default {
  name: "ForgotPassword",
  data() {
    return {
      email: "",
      resetMailSent: false,
      heading: "",
    };
  },
  computed: {
    resetPasswordDisabled: function() {
      if (this.email.length > 0) {
        return false;
      } else {
        return true;
      }
    }
  },
  methods: {
    sendPasswordResetMail() {
      if (this.email.length === 0) {
        return;
      } else {
        const _fb = new FormData();
        _fb.append("reset_password[email]", this.email);
        utilFunctionService.showLoader();
        api
          .sendPasswordResetMail(_fb)
          .then(() => {
            utilFunctionService.hideLoader();
            utilFunctionService.showSuccess(
              "Password reset link sent"
            );
            this.resetMailSent = true;
          })
          .catch((error) => {
            utilFunctionService.hideLoader();
            if (error.response.status === 404)
              utilFunctionService.showerr(
                "Email address not found. Please try again"
              );
          });
      }
    },
    returnToLogin() {
      this.$router.replace({ name: "login" });
    },
  },
};
</script>

<style scoped lang="scss">
@import "../styles/variables.scss";
@import "../styles/mixin.scss";
.login-container {
  @include flexProperty(center, center, column);
  padding: 20px 0;
  min-height: 100vh;
  @include media-breakpoint(sm) {
    background-color: $theme-light-background;
  }
  a {
    text-decoration: underline $blue-color solid 1px !important;
  }
  .login-logo {
    width: 180px;
    margin: 0 0 30px;
  }
  .reset-form {
    width: 100%;
    border: 1px solid #eaeaea;
    background-color: $white;
    border-radius: 4px;
    padding: 40px 50px;
    @include media-breakpoint(md) {
      width: 500px;
      background-color: $white;
      padding: 40px 50px;
    }
  }
  h1 {
    @include font-bold;
    font-size: 32px;
    margin: 0 0 30px;
  }
  label {
    @include font-medium;
  }
  input {
    border: 1px solid #eaeaea;
  }
}
.btn[aria-disabled=true] {
  opacity: 0.5;
  cursor: default;
}
</style>
