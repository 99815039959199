<template>
  <main id="register" class="register-container">
    <div id="logo-box">
      <router-link to="/" tabindex="0">
        <img
          src="../assets/images/ametros-learning-logo.svg"
          alt="Ametros Learning Logo"
          class="register-logo"
          aria-label="Login"
        />
      </router-link>
    </div>

    <payment-form
      v-if="showPaymentForm"
      :course_name="this.course_name"
      :price="this.price"
      :tax_rate="this.tax_rate"
      :taxable="this.taxable"
      :final_price="this.final_price"
      :loggedIn="this.loggedIn"
      :learner_registration_id="this.learner_registration_id"
      :currency="this.currency"
    >
    </payment-form>

    <div v-if="loggedIn == false">
      <div id="user-choice" v-if="hideUserChoice == false">
        <button
          @click="
            showNewRegister = true;
            hideUserChoice = true;
          "
          class="btn secondary small"
          v-if="showNewRegister == false"
        >
          I'm new to Ametros
        </button>
        <button
          @click="
            showExistingRegister = true;
            hideUserChoice = true;
          "
          class="btn secondary small"
        >
          I already have an Ametros account
        </button>
      </div>

      <div class="register-form" v-if="showNewRegister">
        <h1>New User Registration</h1>

        <ValidationObserver ref="form">
          <form class="w-100">
            <div class="form-group">
              <ValidationProvider
                name="Registration Code"
                rules="required"
                v-slot="{ errors }"
                v-if="!fromPearson"
              >
                <input
                  class="form-control"
                  type="text"
                  tabindex="0"
                  placeholder="Enter registration code"
                  aria-label="Enter registration code"
                  v-model="input.reg_code"
                  @input="getRegCode(input.reg_code)"
                  @blur="checkForLearnerId()"
                  :class="[isActive && errors[0] ? 'invalid' : '']"
                />
                <span
                  :class="[isActive ? 'invalid' : '']"
                  :style="[
                    isActive ? { display: 'block' } : { display: 'none' },
                  ]"
                  v-if="errors[0]"
                  >{{ errors[0].replace("The ", "") }}</span
                >
                <span
                  :class="[isActive ? 'invalid' : '']"
                  :style="[
                    isActive ? { display: 'block' } : { display: 'none' },
                  ]"
                  v-if="RegCodeError && showError"
                  >Incorrect registration code</span
                >
                <span
                    :class="[isActive ? 'invalid' : '']"
                    :style="[
                      isActive ? { display: 'block' } : { display: 'none' },
                    ]"
                    v-if="RegCodeUsedError && showError"
                    >This single use registration code has already been used and is no longer valid.</span
                  >
              </ValidationProvider>
              <div></div>
            </div>
            <div class="form-group">
              <ValidationProvider
                name="First name"
                rules="required"
                v-slot="{ errors }"
              >
                <input
                  type="text"
                  tabindex="0"
                  name="first_name"
                  class="form-control"
                  v-model="input.first_name"
                  placeholder="Enter first name"
                  aria-label="Enter first name"
                  :class="[isActive && errors[0] ? 'invalid' : '']"
                />
                <span
                  :class="[isActive ? 'invalid' : '']"
                  :style="[
                    isActive ? { display: 'block' } : { display: 'none' },
                  ]"
                  v-if="errors[0]"
                  >{{ errors[0].replace("The ", "") }}</span
                >
              </ValidationProvider>
            </div>

            <div class="form-group">
              <!-- <label>Last Name</label> -->
              <ValidationProvider
                name="Last name"
                rules="required"
                v-slot="{ errors }"
              >
                <input
                  type="text"
                  name="last_name"
                  tabindex="0"
                  class="form-control"
                  v-model="input.last_name"
                  placeholder="Enter last name"
                  aria-label="Enter last name"
                  :class="[isActive && errors[0] ? 'invalid' : '']"
                />
                <span
                  :class="[isActive ? 'invalid' : '']"
                  :style="[
                    isActive ? { display: 'block' } : { display: 'none' },
                  ]"
                  v-if="errors[0]"
                  >{{ errors[0].replace("The ", "") }}</span
                >
              </ValidationProvider>
            </div>

            <div class="form-group">
              <ValidationProvider
                name="Email"
                rules="required|email"
                v-slot="{ errors }"
              >
                <input
                  type="email"
                  name="email"
                  tabindex="0"
                  class="form-control"
                  v-model="input.email"
                  placeholder="Enter email"
                  aria-label="Enter email"
                  :class="[isActive && errors[0] ? 'invalid' : '']"
                />
                <span
                  :class="[isActive ? 'invalid' : '']"
                  :style="[
                    isActive ? { display: 'block' } : { display: 'none' },
                  ]"
                  v-if="errors[0]"
                  >{{ errors[0].replace("The ", "") }}</span
                >
                <span
                  :class="[isActive ? 'invalid' : '']"
                  :style="[
                    isActive ? { display: 'block' } : { display: 'none' },
                  ]"
                  v-if="emailError && showError"
                  >Email already taken / Invalid</span
                >
              </ValidationProvider>
            </div>

            <div class="form-group">
              <ValidationProvider
                name="Password"
                rules="required|min:6"
                v-slot="{ errors }"
              >
                <input
                  type="password"
                  name="password"
                  tabindex="0"
                  class="form-control"
                  v-model="input.password"
                  placeholder="Create a password (at least 6 characters)"
                  aria-label="Create a password (at least 6 characters)"
                  :class="[isActive && errors[0] ? 'invalid' : '']"
                />
                <span
                  :class="[isActive ? 'invalid' : '']"
                  :style="[
                    isActive ? { display: 'block' } : { display: 'none' },
                  ]"
                  v-if="errors[0]"
                  >{{ errors[0].replace("The ", "") }}</span
                >
              </ValidationProvider>
            </div>

            <div class="form-group">
              <ValidationProvider
                name="Confirm password"
                rules="required|min:6"
                v-slot="{ errors }"
              >
                <input
                  type="password"
                  name="password_confirm"
                  tabindex="0"
                  class="form-control"
                  v-model="input.password_confirm"
                  placeholder="Confirm password"
                  aria-label="Confirm password"
                  :class="[isActive && errors[0] ? 'invalid' : '']"
                  @blur="confirmPassword()"
                />
                <span
                  :class="[isActive ? 'invalid' : '']"
                  :style="[
                    isActive ? { display: 'block' } : { display: 'none' },
                  ]"
                  v-if="errors[0]"
                  >{{ errors[0].replace("The ", "") }}</span
                >
                <span
                  :class="[isActive ? 'invalid' : '']"
                  :style="[
                    isActive ? { display: 'block' } : { display: 'none' },
                  ]"
                  v-if="passwordMismatch"
                  >Please make sure your passwords match</span
                >
              </ValidationProvider>
            </div>

            <div class="form-group" v-if="displayStudentIdField">
              <ValidationProvider
                name="Student ID"
                rules="required"
                v-slot="{ errors }"
                v-if="!fromPearson"
              >
                <input
                  class="form-control"
                  type="text"
                  tabindex="0"
                  placeholder="Enter student ID"
                  aria-label="Enter student ID"
                  v-model="input.student_id"
                  @input="input.student_id"
                  :class="[isActive && errors[0] ? 'invalid' : '']"
                />
                <span
                  :class="[isActive ? 'invalid' : '']"
                  :style="[
                    isActive ? { display: 'block' } : { display: 'none' },
                  ]"
                  v-if="errors[0]"
                  >{{ errors[0].replace("The ", "") }}</span
                >
                <span
                  :class="[isActive ? 'invalid' : '']"
                  :style="[
                    isActive ? { display: 'block' } : { display: 'none' },
                  ]"
                  v-if="studentIderr && showError"
                  >Incorrect student ID / already taken</span
                >
              </ValidationProvider>
            </div>

            <div class="form-group">
              <div class="form-checkboxes">
                <input
                  type="checkbox"
                  id="accept_terms"
                  aria-label="Accept Terms"
                  tabindex="0"
                  v-model="acceptTerms"
                  @change="getCheckboxValue"
                  @keyup.enter="setTermsCheckboxValue"
                  v-bind:value="acceptTerms"
                />

                <span
                  class="custom-checkbox accept-terms right"
                  for="accept_terms"
                >
                  I accept the
                  <a
                    @click="showTermsConditions"
                    @keyup.enter="showTermsConditions"
                    tabindex="0"
                    >terms and conditions</a
                  >
                </span>
              </div>
              <div>
                <p v-if="showTermsConditionsError">
                  You must accept the terms and conditions to register
                </p>
              </div>
            </div>

            <div class="text-end">
              <button
                type="button"
                class="btn btn-ametros"
                tabindex="0"
                :disabled="!acceptTerms"
                @keyup.enter="register()"
                @click="register()"
              >
                Register
              </button>
            </div>
          </form>
        </ValidationObserver>
      </div>

      <div
        id="register-login"
        class="register-login-container"
        v-if="showExistingRegister"
      >
        <div class="login-form">
          <h1>Returning User Registration</h1>
          <ValidationObserver ref="form">
            <form class="w-100">
              <div class="form-group">
                <ValidationProvider
                  name="Registration Code"
                  rules="required"
                  v-slot="{ errors }"
                  v-if="!fromPearson"
                >
                  <input
                    class="form-control"
                    type="text"
                    tabindex="0"
                    aria-label="Enter registration code"
                    placeholder="Enter registration code"
                    v-model="input.reg_code"
                    @input="getRegCode(input.reg_code)"
                    @blur="checkForLearnerId()"
                    :class="[isActive && errors[0] ? 'invalid' : '']"
                  />
                  <span
                    :class="[isActive ? 'invalid' : '']"
                    :style="[
                      isActive ? { display: 'block' } : { display: 'none' },
                    ]"
                    v-if="errors[0]"
                    >{{ errors[0].replace("The ", "") }}</span
                  >
                  <span
                    :class="[isActive ? 'invalid' : '']"
                    :style="[
                      isActive ? { display: 'block' } : { display: 'none' },
                    ]"
                    v-if="RegCodeError && showError"
                    >Incorrect registration code</span
                  >
                </ValidationProvider>
              </div>
              <div class="form-group">
                <ValidationProvider
                  name="Email"
                  rules="required"
                  v-slot="{ errors }"
                  v-if="!fromPearson"
                >
                  <input
                    type="text"
                    class="form-control"
                    tabindex="0"
                    name="username"
                    v-model="input.email"
                    aria-label="Enter email address"
                    placeholder="Enter email address"
                  />
                  <span
                    :class="[isActive ? 'invalid' : '']"
                    :style="[
                      isActive ? { display: 'block' } : { display: 'none' },
                    ]"
                    v-if="errors[0]"
                    >{{ errors[0].replace("The ", "") }}</span
                  >
                  <span
                    :class="[isActive ? 'invalid' : '']"
                    :style="[
                      isActive ? { display: 'block' } : { display: 'none' },
                    ]"
                    v-if="emailError && showError"
                    >Incorrect email</span
                  >
                </ValidationProvider>
              </div>
              <div class="form-group">
                <input
                  type="password"
                  name="password"
                  tabindex="0"
                  class="form-control"
                  v-model="input.password"
                  aria-label="Enter password"
                  placeholder="Enter password"
                />
              </div>
              <div class="form-group" v-if="displayStudentIdField">
                <ValidationProvider
                  name="Student ID"
                  rules="required"
                  v-slot="{ errors }"
                  v-if="!fromPearson"
                >
                  <input
                    class="form-control"
                    type="text"
                    tabindex="0"
                    aria-label="Enter student ID"
                    placeholder="Enter student ID"
                    v-model="input.student_id"
                    @input="input.student_id"
                    :class="[isActive && errors[0] ? 'invalid' : '']"
                  />
                  <span
                    :class="[isActive ? 'invalid' : '']"
                    :style="[
                      isActive ? { display: 'block' } : { display: 'none' },
                    ]"
                    v-if="errors[0]"
                    >{{ errors[0].replace("The ", "") }}</span
                  >
                  <span
                    :class="[isActive ? 'invalid' : '']"
                    :style="[
                      isActive ? { display: 'block' } : { display: 'none' },
                    ]"
                    v-if="studentIderr && showError"
                    >Incorrect student ID</span
                  >
                </ValidationProvider>
              </div>
              <router-link to="/forgot-password" tabindex="0">
                Forgot password?
              </router-link>
              <div class="text-end">
                <button
                  type="button"
                  class="btn btn-ametros"
                  tabindex="0"
                  @click="loginRegister()"
                  @keyup.enter="loginRegister()"
                >
                  Register and Login
                </button>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>

    <div v-else>
      <div
        id="register-login"
        class="register-login-container"
        v-if="hideAlreadyHaveAccount == false && !fromPearson"
      >
        <div class="login-form">
          <h1>Register Module</h1>
          <p>
            Enter a registration code below to add a new module to your Ametros
            account.
          </p>
          <br />
          <ValidationObserver ref="form">
            <form class="w-100">
              <div class="form-group">
                <ValidationProvider
                  name="Registration Code"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <input
                    class="form-control"
                    type="text"
                    aria-label="Enter registration code"
                    placeholder="Enter registration code"
                    v-model="input.reg_code"
                    @input="getRegCode(input.reg_code)"
                    @blur="checkForLearnerId()"
                    :class="[isActive && errors[0] ? 'invalid' : '']"
                  />
                  <span
                    :class="[isActive ? 'invalid' : '']"
                    :style="[
                      isActive ? { display: 'block' } : { display: 'none' },
                    ]"
                    v-if="errors[0]"
                    >{{ errors[0].replace("The ", "") }}</span
                  >
                  <span
                    :class="[isActive ? 'invalid' : '']"
                    :style="[
                      isActive ? { display: 'block' } : { display: 'none' },
                    ]"
                    v-if="RegCodeError && showError"
                    >Incorrect registration code</span
                  >
                  <span
                    :class="[isActive ? 'invalid' : '']"
                    :style="[
                      isActive ? { display: 'block' } : { display: 'none' },
                    ]"
                    v-if="RegCodeUsedError && showError"
                    >This single use registration code has already been used and is no longer valid.</span
                  >
                </ValidationProvider>
              </div>

              <div class="form-group" v-if="displayStudentIdField">
                <ValidationProvider
                  name="Student ID"
                  rules="required"
                  v-slot="{ errors }"
                  v-if="!fromPearson"
                >
                  <input
                    class="form-control"
                    type="text"
                    aria-label="Enter student ID"
                    placeholder="Enter student ID"
                    v-model="input.student_id"
                    @input="input.student_id"
                    :class="[isActive && errors[0] ? 'invalid' : '']"
                  />
                  <span
                    :class="[isActive ? 'invalid' : '']"
                    :style="[
                      isActive ? { display: 'block' } : { display: 'none' },
                    ]"
                    v-if="errors[0]"
                    >{{ errors[0].replace("The ", "") }}</span
                  >
                  <span
                    :class="[isActive ? 'invalid' : '']"
                    :style="[
                      isActive ? { display: 'block' } : { display: 'none' },
                    ]"
                    v-if="studentIderr && showError"
                    >Incorrect student ID</span
                  >
                </ValidationProvider>
              </div>

              <div class="text-end">
                <button
                  type="button"
                  class="btn btn-ametros"
                  @click.once="loggedinRegister()"
                >
                  Register
                </button>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
    <terms-conditions></terms-conditions>
  </main>
</template>

<script>
import api from "../services/api";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { utilFunctionService } from "@/utils/utils.service";
import { commonConfig } from "@/utils/commonConfig";
import PaymentForm from "@/components/PaymentForm.vue";
import termsConditions from "@/components/termsConditions.vue";
import { mapGetters } from "vuex";

export default {
  watch: {
    title: {
      immediate: true,
      handler() {
        document.title = this.title;
      },
    },
  },
  name: "Register-component",
  components: {
    ValidationProvider,
    ValidationObserver,
    PaymentForm,
    termsConditions,
  },
  props: ["regcode", "fromPearson"],
  computed: {
    ...mapGetters("user", {
      userDetails: "getUserDetails",
    }),
  },
  data() {
    return {
      input: {
        reg_code: "",
        student_id: "",
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        password_confirm: "",
      },
      payment_form: {
        card_number: "",
        expiration_month: "",
        expiration_year: "",
        verification_code: "",
      },
      title: "Register",
      url: process.env.VUE_APP_URL,
      RegCodeError: false,
      studentIderr: false,
      acceptTerms: false,
      showTermsConditionsError: false,
      isActive: "",
      showError: false,
      passwordMismatch: false,
      showNewRegister: false,
      showExistingRegister: false,
      hideUserChoice: false,
      showPaymentForm: false,
      loggedIn: false,
      currency: "",
      price: "",
      tax_rate: "",
      taxable: true,
      final_price: "",
      order_id: "",
      end_user_paid: false,
      account_exists: false,
      payment_attempt: true,
      course_name: "",
      purchase_result: "",
      showPaymentError: false,
      learner_registration_id: "",
      learnerRegData: {},
      payment_type: "",
      no_charge: false,
      courseData: {},
      customer_course_id: null,
      paymentSuccess: false,
      loggedinPaymentSuccess: false,
      loggedinShowPaymentError: false,
      hideAlreadyHaveAccount: false,
      loggedinShowPaymentForm: false,
      emailError: false,
      displayStudentIdField: false,
      RegCodeUsedError: false
    };
  },
  created() {
    const userDetails = localStorage.getItem("vuex");
    if (userDetails) {
      this.loggedIn = true;
      this.email = userDetails.user.user.attributes.email;
      this.first_name = userDetails.user.user.attributes.first_name;
      this.last_name = userDetails.user.user.attributes.last_name;
    }
    if (this.loggedIn == true && this.fromPearson) {
      this.loggedinRegister();
    }
  },
  methods: {
    register() {
      utilFunctionService.showLoader();
      this.$refs.form.validate().then((success) => {
        this.isActive = true; 
        if (!success || this.passwordMismatch || this.checkisRyersonEmail()) {
          utilFunctionService.hideLoader();
          return;
        } else {
          if (this.acceptTerms === false) {
            utilFunctionService.hideLoader();
            this.showTermsConditionsError = true;
          } else {
            const form = {
              email: this.input.email,
              password: this.input.password,
              password_confirm: this.input.password_confirm,
              first_name: this.input.first_name,
              last_name: this.input.last_name,
              reg_code: this.fromPearson ? this.regcode : this.input.reg_code,
              id_for_learner_customer: this.input.student_id,
              existing_account: this.account_exists,
            };
            api
              .postRegister(form)
              .then((res) => {
                if (res && res.data) {
                  if (res.data.data.attributes.customer_course_id == null) {
                    utilFunctionService.hideLoader();
                    utilFunctionService.showSuccess(
                      commonConfig.appMessages.registerSuccess
                    );
                    this.$router.replace({ name: "login" });
                  } else {
                    utilFunctionService.hideLoader();
                    this.learnerRegData = res.data.data;
                    this.customer_course_id =
                      res.data.data.attributes.customer_course.id;
                    this.courseData =
                      res.data.data.attributes.customer_course.attributes;
                    this.learner_registration_id = this.learnerRegData.id;
                    this.account_exists =
                      this.learnerRegData.attributes.existing_account;
                    this.course_name =
                      this.learnerRegData.attributes.course_name;
                    this.payment_type =
                      this.learnerRegData.attributes.payment_type;
                    this.price = parseFloat(this.courseData.price);
                    this.tax_rate = parseFloat(this.courseData.tax_rate);

                    this.no_charge = this.courseData.no_charge;

                    this.currency = this.courseData.currency;
                    this.final_price = this.price + this.price * this.tax_rate;
                    if (this.payment_type == "end_user" && this.no_charge == false) {
                      this.showPaymentForm = true;
                      this.showNewRegister = false;
                    } else {
                      utilFunctionService.showSuccess(
                        commonConfig.appMessages.registerSuccess
                      );
                      this.$router.replace({ name: "login" });
                    }
                  }
                }
              })
              .catch((error) => {
                utilFunctionService.hideLoader();
                if (error.response && error.response.data) {
                  let res = error.response.data;
                  this.showError = true;
                  this.RegCodeError = !res.valid_code;
                  this.emailError = res.user_exists;
                  this.studentIderr = !res.valid_id;
                  this.RegCodeUsedError = res.reg_code_used;
                }
              });
          }
        }
      });
    },
    loginRegister() {
      this.isActive = true;
      const user = {
        email: this.input.email,
        password: this.input.password,
      };
      const form = {
        email: this.input.email,
        password: this.input.password,
        password_confirm: this.input.password_confirm,
        first_name: "Temp",
        last_name: "Name",
        reg_code: this.fromPearson ? this.regcode : this.input.reg_code,
        id_for_learner_customer: this.input.student_id,
        existing_account: true,
      };

      if (this.checkisRyersonEmail()) {
        utilFunctionService.hideLoader();
        utilFunctionService.showerr(
          "Please use your TorontoMU email address to register. A ryerson.ca cannot be used to register."
        );
      } else {
        api
        .loginUser(user)
        .then((res) => {
          const result = JSON.stringify(res["data"]);
          this.$store.dispatch("user/setUserData", result);
          api
            .postRegister(form)
            .then((res) => {
              if (res && res.data) {
                if (res.data.data.attributes.customer_course_id == null) {
                  this.$router.replace({ name: "active" });
                  utilFunctionService.showSuccess(
                    commonConfig.appMessages.RegisterSuccess
                  );
                } else {
                  this.learnerRegData = res.data.data;
                  this.customer_course_id =
                    res.data.data.attributes.customer_course.id;
                  this.courseData =
                    res.data.data.attributes.customer_course.attributes;
                  this.learner_registration_id = this.learnerRegData.id;
                  this.account_exists =
                    this.learnerRegData.attributes.existing_account;
                  this.course_name = this.learnerRegData.attributes.course_name;
                  this.payment_type =
                    this.learnerRegData.attributes.payment_type;
                  this.price = parseFloat(this.courseData.price);
                  this.tax_rate = parseFloat(this.courseData.tax_rate);

                  this.currency = this.courseData.currency;
                  this.final_price = this.price + this.price * this.tax_rate;
                  if (this.payment_type == "end_user") {
                    this.showPaymentForm = true;
                    this.showExistingRegister = false;
                  } else {
                    utilFunctionService.showSuccess(
                      commonConfig.appMessages.registerSuccess
                    );
                    this.$router.replace({ name: "active" });
                  }
                }
              }
            })
            .catch((error) => {
              if (error.response && error.response.data) {
                let res = error.response.data;
                this.showError = true;
                this.RegCodeError = !res.valid_code;
                this.studentIderr = !res.valid_id;
                this.RegCodeUsedError = res.reg_code_used;
                if (!this.RegCodeError && !this.studentIderr) {
                  utilFunctionService.showerr("Something went wrong");
                }
               
              }
            });
        })
        .catch(() => {
          utilFunctionService.showerr(commonConfig.appMessages.loginFailed);
        });
      }
    },
    loggedinRegister(e) {
      this.isActive = true;
      const form = {
        email: this.userDetails.attributes.email,
        password: "placeholder",
        password_confirm: "placholder",
        first_name: this.userDetails.attributes.first_name,
        last_name: this.userDetails.attributes.last_name,
        reg_code: this.fromPearson ? this.regcode : this.input.reg_code,
        id_for_learner_customer: this.input.student_id,
        existing_account: true,
      };
      if (this.input.reg_code != null) {
        const fb = { reg_code: this.input.reg_code };
        api.checkForLearnerId(fb).then((res) => {
          this.displayStudentIdField = res.data.student_id_required;

          if (this.displayStudentIdField && this.input.student_id == "") {
            e.preventDefault();
          } else {
            api
              .postRegister(form)
              .then((res) => {
                if (res && res.data) {
                  if (res.data.data.attributes.customer_course_id == null) {
                    utilFunctionService.showSuccess(
                      commonConfig.appMessages.RegisterSuccess
                    );
                    this.$router.replace({ name: "active" });
                  } else {
                    this.learnerRegData = res.data.data;
                    this.customer_course_id =
                      res.data.data.attributes.customer_course.id;
                    this.courseData =
                      res.data.data.attributes.customer_course.attributes;
                    this.learner_registration_id = this.learnerRegData.id;
                    this.account_exists =
                      this.learnerRegData.attributes.existing_account;
                    this.course_name =
                      this.learnerRegData.attributes.course_name;
                    this.payment_type =
                      this.learnerRegData.attributes.payment_type;
                    this.price = parseFloat(this.courseData.price);
                    this.tax_rate = parseFloat(this.courseData.tax_rate);

                    this.currency = this.courseData.currency;
                    this.final_price = this.price + this.price * this.tax_rate;
                    if (this.payment_type == "end_user") {
                      this.showPaymentForm = true;
                      this.hideAlreadyHaveAccount = true;
                    } else {
                      utilFunctionService.showSuccess(
                        commonConfig.appMessages.registerSuccess
                      );
                      this.$router.replace({ name: "login" });
                    }
                  }
                }
              })
              .catch((error) => {
                if (error.response && error.response.data) {
                  let res = error.response.data;
                  this.showError = true;
                  this.RegCodeError = !res.valid_code;
                  this.studentIderr = !res.valid_id;
                  this.RegCodeUsedError = res.reg_code_used;
                  
                }
              });
          }
        });
      }
    },
    showTermsConditions() {
      this.$modal.show("TermsAndConditions");
    },
    setTermsCheckboxValue() {
      if (this.acceptTerms) {
        this.acceptTerms = false;
      } else {
        this.acceptTerms = true;
      }
    },
    getCheckboxValue() {
      if (this.acceptTerms) {
        this.showTermsConditionsError = false;
      } else {
        this.showTermsConditionsError = true;
      }
    },
    getRegCode() {
      this.showError = false;
    },
    checkForLearnerId() {
      if (this.input.reg_code != null) {
        const fb = { reg_code: this.input.reg_code };
        api.checkForLearnerId(fb).then((res) => {
          this.displayStudentIdField = res.data.student_id_required;
          
        });
      }
    },
    confirmPassword() {
      if (this.input.password !== this.input.password_confirm) {
        this.passwordMismatch = true;
      } else {
        this.passwordMismatch = false;
      }
    },
    checkisRyersonEmail() {
      if (this.input.email.includes("@ryerson.ca")) {
        utilFunctionService.showerr(
          "Please use your TorontoMU email address to register. A ryerson.ca cannot be used to register."
        );
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "../styles/variables.scss";
@import "../styles/mixin.scss";
#logo-box {
  display: flex;
}
#user-choice {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 500px;
  margin-bottom: 1em;
  margin-top: 2em;
  button {
    width: 100%;
    margin-bottom: 32px;
  }
}

.credit-card-header {
  margin-bottom: 1em;
  margin-top: 1em;
}

.credit-card-form {
  margin-top: 2em;
}

.payment-problem {
  padding: 1em;
  background-color: rgb(244, 245, 208);
}

.register-container {
  @include flexProperty(center, center, column);
  padding: 20px 0;
  min-height: 100vh;
  @include media-breakpoint(sm) {
    background-color: $theme-light-background;
  }
  a {
    text-decoration: underline $blue-color solid 1px !important;
  }
  .register-logo {
    width: 180px;
    margin: 0 auto;
    padding-bottom: 2em;
  }
  .register-form {
    width: 100%;
    border: 1px solid #eaeaea;
    background-color: $white;
    border-radius: 4px;
    padding: 40px 50px;
    @include media-breakpoint(md) {
      width: 500px;
      background-color: $white;
      padding: 40px 50px;
    }
  }
  .left-align {
    text-align: left;
  }
  h1 {
    @include font-bold;
    //text-transform: uppercase;
    font-size: 28px;
    margin: 0 0 40px;
    text-align: left;
  }
  label {
    @include font-medium;
  }
  input {
    border: 1px solid #eaeaea;
  }
  .modal {
    max-height: 750px;
    overflow-y: auto;
  }
}
</style>