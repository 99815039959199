<template>
	<div class="learner-container">
		<div class="header">
			<h1 class="learn-title">
				{{ active_data.attributes.learning_object.attributes.name }}
			</h1>
			<h2 class="learn-info" v-html="active_data.attributes.learning_object.attributes.description"></h2>
		</div>
		<div class="messages-container">
			<div class="messages-area" v-for="(message, index) in messages" :key="index">
				<transition name="up">
					<div v-if="message.question" class="bubble robot-bubble">
						<div class="character-container">
							<figure class="avatar">
								<img v-if="characterImageURL" :src="characterImageURL" alt="" />
								<img v-else src="../assets/images/default-user.svg" alt="" />
							</figure>
							{{ characterName }}
						</div>
						<div class="character-response">
							{{ message.question }}
						</div>
					</div>
				</transition>
				<transition name="up">
					<div v-if="message.answer" class="bubble learner-bubble">
						{{ message.answer }}
					</div>
				</transition>
				<transition name="up">
					<div v-if="message.response" class="bubble robot-bubble">
						<div class="character-container">
							<figure class="avatar">
								<img v-if="characterImageURL" :src="characterImageURL" alt="" />
								<img v-else src="../assets/images/default-user.svg" alt="" />
							</figure>
							<h4 class="character-name">{{ characterName }}</h4>
						</div>
						<div v-for="(characterResponse, index) in message.response" :key="index">
							<div class="character-response">
								{{ characterResponse.response }}
							</div>
						</div>
					</div>
				</transition>
			</div>
			<div class="messages-area">
				<transition name="up" v-if="loading">
					<div class="bubble robot-bubble">
						<div class="character-loading loading">
							<span></span>
						</div>
					</div>
				</transition>
			</div>
		</div>
		<transition name="fade">
			<div v-if="hasStarted" class="spacer"></div>
		</transition>
		<div class="chat">
			<transition name="fade">
				<div v-if="assessmentLoading">Your assessment is processing.</div>
			</transition>
			<div class="messages-buttons-container" v-if="!llmComplete">
				<textarea 
					:disabled="loading || assessmentLoading" 
					class="input-area" v-model="userAnswer"
					@keydown.enter.prevent
					@copy="handleCopyPaste"
					@paste="handleCopyPaste"
				></textarea>
				<button :disabled="loading || assessmentLoading || llmComplete || userAnswer.trim() == ''"
					class="button submit-button" @click="submitAnswer">
					Submit your message
				</button>
			</div>
		</div>
		<div class="button-container">
			<button class="button" v-if="user_roles.includes('testing-internal')" @click="resetLlmConversation">
				Reset chat history
			</button>
			<button class="button" v-if="reattemptsEnabled" @click="retryInteraction">
				Reattempt Interaction
			</button>
			<button v-if="hasStarted && llmComplete" :disabled="loading || assessmentLoading" class="button"
				@click="submitAssessmentRequest">
				Get Conversation Assessment
			</button>
			<lo-buttons v-if="llmComplete" text="Next" :nextMethod="next" :showCertificateOfCompletion="active_data.attributes.enable_certificate_of_completion
				"></lo-buttons>
		</div>
		<llm-feedback-modal></llm-feedback-modal>
		<llm-moat-feedback-modal></llm-moat-feedback-modal>
	</div>
</template>

<script>
import { utilFunctionService } from "@/utils/utils.service";
import api from "../services/api";
import loButtons from "./loButtons.vue";
import llmFeedbackModal from "./llmFeedbackModal.vue";
import llmMoatFeedbackModal from "./llmMOATFeedbackModal.vue";

export default {
	name: "llm-dialogic",
	props: ["active_data", "nextStatus", "user_roles"],
	components: {
		loButtons,
		llmFeedbackModal,
		llmMoatFeedbackModal,
	},
	data() {
		return {
			messages: [
				{
					question: "",
					answer: "",
					response: "",
				},
			],
			userAnswer: "",
			loading: false,
			assessmentLoading: false,
			feedback: "",
			hasStarted: false,
			finishedLoading: false,
			numberOfAttempts: 0,
			attemptsLimit: 3,
			llmComplete: false,
			showAssessment: false,
			showFeedback: false,
			dialogicQuestions: [],
			characterName: "",
			answer: '',
			isMoatCustom: false,
			qaItems: [],
			showChatLog: true,
			responseToAdd: "",
			conclusionAdded: false,
			final_feedback_loading: false,
			evaluationId: null,
			moat_lo_id: null,
			dialogic_roleplay: false,
			reattemptsEnabled: false,
			maxAttempts: 0,
			disablePastingText: true,
			characterImageURL: "",
			flaggedMessages: 0
		};
	},
	methods: {
		handleCopyPaste(event) {
			if (this.disablePastingText === true) {
				event.preventDefault();
				utilFunctionService.preventCopyPaste(event);
			}
		},
		submitAnswer() {
			this.loading = true;
			this.messages.push({
				question: "",
				answer: this.userAnswer,
				response: "",
			});
			let userDetail = this.active_data.attributes.user_section.attributes.user;
			this.$cable.subscribe({
				channel: "AssessmentAnswerChannel",
				room: userDetail.id,
			});
			this.userAnswer = "";
		},
		retryInteraction() {
			let module_id = this.active_data.attributes.learning_object.attributes.learn_mod_id;
			let user_learn_obj_id = this.active_data.id;
			this.$modal.show("dialog", {
				title: "Are you sure you would like to try again? Please note that any work submitted in your previous attempt at this interaction will not be saved. Your assessment on previous attempts will still be available in your final feedback.",
				text: "",
				buttons: [
					{
						title: "Cancel",
						handler: () => {
							this.$modal.hide("dialog");
						},
					},
					{
						title: "Yes I'm sure",
						handler: () => {
							utilFunctionService.showLoader();
							api.retryInteraction(module_id, user_learn_obj_id).then(() => {
								utilFunctionService.hideLoader();
								location.reload();
							}).catch(() => {
								utilFunctionService.hideLoader();
								utilFunctionService.showerr("An error has occurred. Please contact Ametros support for assistance.")
							})
						},
					},
				],
			});
		},
		submitAssessmentRequest() {
			let userDetail = this.active_data.attributes.user_section.attributes.user;
			let cardDetail =
				this.active_data.attributes.learning_object.attributes.card_detail.attributes;
			// If there is an existing assessment id, then we can just get the assessment result
			if (this.evaluationId) {
				utilFunctionService.showLoader();
				this.assessmentLoading = true;
				api.getLlmDialogicAssessment(this.evaluationId).then((res) => {
					console.log(res);
					const response = res.data.data.attributes;
					if ((response.assessment_results == null || response.assessment_results.length == 0) && (response.learner_assessments == null|| response.learner_assessments.length == 0)) {
					api.removeLlmDialogicAssessment(this.evaluationId).then(() => {
						this.evaluationId = null;
						this.$cable.subscribe({
						channel: "AssessmentResultChannel",
						room: userDetail.id,
						});
					});
					} else {
						this.assessmentLoading = false;
						utilFunctionService.hideLoader();
						if (this.isMoatCustom) {
							this.$modal.show("llmMoatFeedbackModal", { feedback: res.data.data.attributes, llm_type: cardDetail.llm_type });
						} else {
							this.$modal.show("llmFeedbackModal", { feedback: res.data.data.attributes, llm_type: cardDetail.llm_type });
						}
					}
				});
			} else {
				// If there is no existing assessment, we need to create one
				this.$modal.show('dialog', {
					title: 'Are you sure? Once your assessment is generated, you will not be able to send any more messages in this conversation.',
					buttons: [
						{
							title: 'Cancel',
							handler: () => {
								this.$modal.hide('dialog')
							}
						},
						{
							title: 'Submit',
							handler: () => {
								this.$modal.hide('dialog')
								this.assessmentLoading = true;
								utilFunctionService.showLoader();
								this.$cable.subscribe({
									channel: "AssessmentResultChannel",
									room: userDetail.id,
								});
							}
						},
					]
				})
			}
		},
		resetLlmConversation() {
			let userDetail = this.active_data.attributes.user_section.attributes.user;
			let payload = {
				student_id: userDetail.id,
				lo_id: this.active_data.attributes.learning_object_id,
				user_learn_obj_id: this.active_data.id
			};
			api.resetLlmConversation(payload).then((res) => {
				console.log(res);
				utilFunctionService.showSuccess("Reset Successfully. Page will refresh in 2 seconds.");
				this.assessmentLoading = false;
				setTimeout(() => {
					location.reload();
				}, 2000);
			});
		},
		next() {
			if (this.evaluationId) {
				this.$parent.next();
			} else {
				utilFunctionService.showSuccess("Please click 'Get Conversation Assessment' to complete the interaction.");
			}
		},
		refreshLlmConversation() {
			let cardDetail = this.active_data.attributes.learning_object.attributes.card_detail.attributes;
			let payload = { user_learn_obj_id: this.active_data.id };
			api.getLlmChatHistory(payload).then((res) => {
				let chatHistoryData = res.data.data;
				let lastMessage = this.messages[this.messages.length - 1];
				lastMessage.response = chatHistoryData[chatHistoryData.length - 1].attributes.response;
				if (this.hasStarted && !this.llmComplete && chatHistoryData.length > 0 && chatHistoryData[chatHistoryData.length - 1].attributes.llm_complete) {
					this.llmComplete = chatHistoryData[chatHistoryData.length - 1].attributes.llm_complete;
				}
				if (this.llmComplete && !this.isMoatCustom) {
					let conclusionMessage = [];
					conclusionMessage.push({ response: cardDetail.conclusion_message });
					console.log(conclusionMessage)
					console.log(this.active_data.attributes.learning_object.attributes.card_detail)
					if (conclusionMessage !== "") {
						this.messages.push({
							answer: "",
							response: conclusionMessage,
						});
					}
				}
				lastMessage.is_flagged = chatHistoryData[chatHistoryData.length - 1].attributes.is_flagged;
				if (lastMessage.is_flagged) {
					if (this.flaggedMessages > 1) {
						this.llmComplete = true;
						utilFunctionService.showerr("You have submitted multiple flagged messages and you cannot continue the rest of the interaction.")
					} else {
						utilFunctionService.showerr("Your message has been flagged for inappropriate content!")
						this.flaggedMessages++
					}
				}
				this.assessmentLoading = false;
			});
		},
		getLlmConversation() {
			let payload = {
				lo_id: this.active_data.attributes.learning_object_id,
				user_learn_obj_id: this.active_data.id
			};
			api.getLlmChatHistory(payload).then((res) => {
				let chatHistoryData = res.data.data;
				chatHistoryData.forEach((chatEl) => {
					this.hasStarted = true;
					let response = "";
					if (chatEl.attributes.response.length > 0) {
						response = chatEl.attributes.response;
					}
					this.messages.push({
						answer: chatEl.attributes.body,
						response: response,
						is_flagged: chatEl.attributes.is_flagged,
					});
				});
				this.messages.forEach((message) => {
					if (message.is_flagged) {
						this.flaggedMessages++
					}
				})
				if (this.flaggedMessages > 1) {
					this.llmComplete = true;
					utilFunctionService.showerr("You have submitted multiple flagged messages and you cannot continue the rest of the interaction.")
				}
			});
		},
		loadingRefreshWarning(event) {
			if (this.loading) {
				event.preventDefault();
				if (confirm("Your message is still being assessed. Are you sure you want to refresh?")) {
					window.location.reload()
				} else {
					return false;
				}
			}
		},
	},

	mounted() {
		// document.addEventListener("keyup", (event) => {
		// 	if (event.key === "Enter" && !this.loading && this.userAnswer.trim() !== "") {
		// 		this.userAnswer = this.userAnswer.replace(/\r\n/g, "").trim();
		// 		if (this.userAnswer !== "") {
		// 			this.submitAnswer();
		// 		}
		// 	}
		// });
		window.addEventListener('beforeunload', this.loadingRefreshWarning);
		const MOAT_LO_IDS = [563, 562, 561, 560, 557, 556, 554, 553, 552, 551, 549]
		const MOAT_LEARN_MOD_ID = this.active_data.attributes.learning_object.attributes.learn_mod_id;
		if (MOAT_LO_IDS.includes(MOAT_LEARN_MOD_ID)) {
			this.isMoatCustom = true;
			this.moat_lo_id = MOAT_LEARN_MOD_ID;
			this.dialogic_roleplay = true;
		}
    if (this.user_roles.includes('testing-internal')) {
      this.disablePastingText = false;
    }
		this.llmComplete = this.active_data.attributes.complete;
		let cardDetail =
			this.active_data.attributes.learning_object.attributes.card_detail;
		this.dialogicQuestions = cardDetail.attributes.dialogic_questions || [];
		if (cardDetail.attributes.dialogic_switch && this.isMoatCustom) {
			if (this.dialogicQuestions.length > 0 && cardDetail.attributes.llm_dialogic_questions.length === 0) {
				this.qaItems = [];
				this.messages[this.messages.length - 1].question = this.dialogicQuestions[0];
			} else {
				this.qaItems = cardDetail.attributes.llm_dialogic_questions;
				this.messages[this.messages.length - 1].question = this.qaItems[0].question;
			}
		} else {
			try {
				this.qaItems = cardDetail.attributes.llm_dialogic_questions;
				if (this.qaItems.length > 0) {
					this.messages[this.messages.length - 1].question = this.qaItems[0].question;
				} else {
					this.messages[this.messages.length - 1].question = this.dialogicQuestions[0];
				}
			} catch (e) {
				this.qaItems = cardDetail.attributes.llm_dialogic_questions;
				this.messages[this.messages.length - 1].question = this.dialogicQuestions[0] || "";
			}
		}
		this.maxAttempts = cardDetail.attributes.max_attempts;
		this.characterName = cardDetail.attributes.character_name;
		this.characterImageURL = cardDetail.attributes.character_image_url;
		this.getLlmConversation();
		this.evaluationId = this.active_data.attributes.current_evaluation_id;
    let attemptsUsed = this.active_data.attributes.reattempt_number;
    if (this.evaluationId && 
      ((cardDetail.attributes.enable_reattempt && attemptsUsed < cardDetail.attributes.max_reattempts) || 
      this.active_data.attributes.course_enable_reattempts && attemptsUsed < this.active_data.attributes.course_enable_reattempts_limit)) {
      this.reattemptsEnabled = true;
    }
	},
	beforeDestroy() {
		window.removeEventListener('beforeunload', this.loadingRefreshWarning);
	},
	channels: {
		AssessmentAnswerChannel: {
			connected() {
				console.log("Learner Assessment Answer connected.");
				console.log("Sending message.")
				this.hasStarted = true;
				const answer = this.messages[this.messages.length - 1].answer;
				let userDetail = this.active_data.attributes.user_section.attributes.user;
				const _fb = new FormData();
				_fb.append("llm_learner_message[body]", answer);
				_fb.append("llm_learner_message[user_learn_obj_id]", this.active_data.id);
				_fb.append("llm_learner_message[lo_id]", this.active_data.attributes.learning_object.attributes.learn_mod_id);
				_fb.append("llm_learner_message[student_id]", userDetail.id);
				_fb.append("llm_learner_message[llm_dialogic_question_id]", this.qaItems[0].id);
				api.submitLlmMessage(_fb).then((res) => {
					console.log(res);
				}).catch((err) => console.log(err));
			},
			received(data) {
				console.log("Learner Assessment Answer received.");
				console.log(data);
        const learner_assessment_answer_data = JSON.parse(data);
				this.loading = false;
				this.$cable.unsubscribe("AssessmentAnswerChannel");
				utilFunctionService.hideLoader();
        if (learner_assessment_answer_data.status == 'error') {
          utilFunctionService.showerr(learner_assessment_answer_data.message);
          this.messages.pop();
          return;
        } else {
          this.refreshLlmConversation();
        }
			},
		},
		AssessmentResultChannel: {
			connected() {
				console.log("Learner Assessment Result connected.");
				let cardDetail = this.active_data.attributes.learning_object.attributes.card_detail;
				let userDetail = this.active_data.attributes.user_section.attributes.user;
				let payload = {
					student_id: userDetail.id,
					lo_id: this.active_data.attributes.learning_object_id,
					user_learn_obj_id: this.active_data.id,
					data: {
						narrative_knowledge_base: this.narrativeKnowledgeBase,
						general_knowledge_base: this.generalKnowledgeBase,
						prompt_data: cardDetail.prompts,
						number_attempts: this.numberOfAttempts,
						attempt_limit: this.attemptsLimit,
					},
					dialogic: cardDetail.dialogic_switch,
				};
				api.submitLlmDialogicAssessment(payload).then((res) => {
					console.log(res);
				});
			},
			received(res) {
				let cardDetail =
					this.active_data.attributes.learning_object.attributes.card_detail
						.attributes;
        let attemptsUsed = this.active_data.attributes.reattempt_number;
				console.log("Learner Assessment received");
				console.log(res);
        this.$cable.unsubscribe("AssessmentResultChannel");
        const learner_assessment_data = JSON.parse(res);
        if (learner_assessment_data.status == 'error') {
          utilFunctionService.showerr(learner_assessment_data.message);
          utilFunctionService.hideLoader();
          return;
        } else {
          this.$cable.unsubscribe("AssessmentResultChannel");
          api.getLlmDialogicAssessment(learner_assessment_data.evaluation_id).then((response) => {
            console.log(response);
            this.evaluationId = response.data.data.id;
            this.assessmentLoading = false;
            this.llmComplete = true;
            if (
              this.evaluationId && 
                ((cardDetail.enable_reattempt && attemptsUsed < cardDetail.max_reattempts) || 
                this.active_data.attributes.course_enable_reattempts && attemptsUsed < this.active_data.attributes.course_enable_reattempts_limit)) 
              {
                this.reattemptsEnabled = true;
            }
            utilFunctionService.hideLoader();
            if (this.isMoatCustom) {
              this.$modal.show("llmMoatFeedbackModal", { feedback: response.data.data.attributes, llm_type: cardDetail.llm_type });
            } else {
              this.$modal.show("llmFeedbackModal", { feedback: response.data.data.attributes, llm_type: cardDetail.llm_type });
            }
          });
        }
			}
		},
	},
};
</script>

<style lang="scss">
@import "../styles/_llm_style.scss";

.learner-container {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
}


.chat {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin-top: 32px;
}

.button {
	padding: 8px;
	border-radius: 5px;
	background: white;
	border: 2px solid green;
	margin-top: 16px;
}

.button:hover {
	background: green;
	color: white;
	cursor: pointer;
	transition: all 0.2s;
}

.button-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-content: center;
	width: 100%;
}

.fade-enter-active,
.fade-leave-active {
	transition: all 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
	{
	opacity: 0;
}

.up-enter-active,
.fade-leave-active {
	transition: all 0.5s;
}

.up-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
	{
	opacity: 0;
	transform: translateY(30px);
}

.feedback {
	width: 50%;
	margin: 25px auto;
	text-align: left;
	line-height: 1.6;
}

.spacer {
	display: block;
	width: 50%;
	margin: 0 auto;
	height: 1px;
	background: black;
}

.header {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	margin-bottom: 128px;
}

.messages-buttons-container {
	display: flex;
	width: 100%;
	padding: 10px;
	position: relative;
	min-height: 75px;
	max-height: 450px;
}

.messages-container {
	display: flex;
	width: 100%;
	padding: 10px;
	position: relative;
	min-height: 75px;
	flex-direction: column;
}

.input-area {
	color: black;
	width: 400px;
	min-height: 75px;
	padding: 16px;
	max-height: 450px;
}

.submit-button {
	border: none;
	font-size: 14px;
	padding: 6px 10px;
	border: 1px solid green;
	margin-top: 0;
}

.avatar {
	border-radius: 30px;
	width: 30px;
	height: 30px;
	overflow: hidden;
	margin: 0;
	padding: 0;
	border: 2px solid rgba(255, 255, 255, 0.24);
	margin-right: 16px;

	img {
		width: 100%;
		height: auto;
	}
}

.character-container {
	position: absolute;
	z-index: 1;
	top: -50px;
	left: 0px;
	background: #3d9bbc;
	padding: 16px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
}

.character-loading {
	position: absolute;
	z-index: 1;
	top: -50px;
	left: 0px;
	background: #3d9bbc;
	padding: 16px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	border-radius: 15px;
}

.character-name {
	font-size: 12px;
	font-weight: 600;
	color: #fff;
	text-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
	margin: 0;
	padding: 0;
}

.scroll-chat {
	overflow: scroll;
	max-height: 450px;
}
</style>