<template>
  <main id="login" class="login-container" v-if="!this.$route.query.id">
    <img
      src="../assets/images/ametros-learning-logo.svg"
      alt="Ametros Learning Logo"
      class="login-logo"
    />
    <div class="login-form" role="form">
      <h1>Login</h1>
      <div class="form-group">
        <label>Email</label>
        <input
          type="email"
          class="form-control"
          tabindex="0"
          name="email"
          v-model="input.email"
          placeholder="Enter email"
          aria-label="Enter email"
        />
      </div>
      <div class="form-group">
        <label>Password</label>
        <div class="position-relative">
          <input
            :type="passwordFieldType"
            name="password"
            tabindex="0"
            class="form-control"
            v-model="input.password"
            placeholder="Enter password"
            aria-label="Enter password"
          />
          <a
            class="forgot-show"
            tabindex="0"
            @click="switchVisibility"
            @keyup.enter="switchVisibility"
            v-if="input.password && passwordFieldType === 'password'"
            >Show</a
          >
          <a
            class="forgot-show"
            tabindex="0"
            @click="switchVisibility"
            @keyup.enter="switchVisibility"
            v-if="input.password && passwordFieldType === 'text'"
            >Hide</a
          >
        </div>
        <router-link to="/forgot-password" aria-label="Forgot Password Link" tabindex="0">
          Forgot password?
        </router-link>
      </div>
      <div class="text-end">
        <button
          aria-label="Login"
          type="button"
          tabindex="0"
          class="btn btn-ametros"
          @click="login()"
          @keyup.enter="login()"
        >
          Log in
        </button>
      </div>
      <div class="text-end mt-3">
        <p>
          Don't have an account?
          <router-link to="/register" aria-label="Register a new account" tabindex="0">
            Register
          </router-link>
        </p>
      </div>
    </div>
  </main>
</template>
<script>
import api from "../services/api";
import { utilFunctionService } from "@/utils/utils.service";

export default {
  watch: {
            title: {
                immediate: true,
                handler() {
                    document.title = this.title;
                }
            }
        },
  name: "Login-component",
  data() {
    return {
      input: {
        email: "",
        password: "",
      },
      title :'Login',
      ltiUserId: "",
      url: process.env.VUE_APP_URL,
      passwordFieldType: "password",
    };
  },
  created() {
    if (this.$route.query && this.$route.query.id) {
      this.ltiUserId = this.$route.query.id;
      let token = this.$route.query.token;
      const _fb = new FormData();
      _fb.append("user[id]", this.ltiUserId);
      _fb.append("user[launch_token]", token);
      api.loginLtiUser(_fb).then((res) => {
        const result = JSON.stringify(res["data"]);
        this.$store.dispatch("user/setUserData", result);
        this.$store.dispatch("user/setUserOrgAndLMSLink", result);
        this.$router.replace({ name: "dashboard" });
      });
    }
  },
  methods: {
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },

    login() {
      const user = {
        email: this.input.email,
        password: this.input.password,
      };
      api
        .loginUser(user)
        .then((res) => {
          const result = JSON.stringify(res["data"]);
          this.$store.dispatch("user/setUserData", result);
          this.$store.dispatch("user/setUserOrgAndLMSLink", result);
          if (
            this.$route.query.name === "learning" &&
            this.$route.query.id !== "undefined" &&
            this.$route.query.loId !== "undefined"
          ) {
            this.$router.replace({
              name: "learning",
              params: {
                id: `${this.$route.query.id}`,
                loId: `${this.$route.query.loId}`,
              },
            });
          } else if (
            this.$route.query.name === "learning" &&
            this.$route.query.id !== "undefined" &&
            this.$route.query.loId == "undefined"
          ) {
            this.$router.replace({
              name: "learning",
              params: {
                id: `${this.$route.query.id}`,
              },
            });
          } else if (this.$route.query && this.$route.query.name) {
            this.$router.replace({ name: `${this.$route.query.name}` });
          } else {
            this.$router.replace({ name: "dashboard" });
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            utilFunctionService.showerr("Incorrect email or password");
          } else if (err.response.status === 511) {
            this.$router.push({ name: "lti-info" });
            utilFunctionService.showerr("LMS Login Required");
          } else {
            utilFunctionService.showerr("An error has occurred. Please reach out to Ametros support.");
          }
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import "../styles/variables.scss";
@import "../styles/mixin.scss";
.login-container {
  @include flexProperty(center, center, column);
  padding: 20px 0;
  min-height: 100vh;
  @include media-breakpoint(sm) {
    background-color: $theme-light-background;
  }
  a {
    text-decoration: underline $blue-color solid 1px !important;
  }
  .login-logo {
    width: 180px;
    margin: 0 0 30px;
  }
  .login-form {
    width: 100%;
    border: 1px solid #eaeaea;
    background-color: $white;
    border-radius: 4px;
    padding: 40px 50px;
    @include media-breakpoint(md) {
      width: 500px;
      background-color: $white;
      padding: 40px 50px;
    }
  }
  .register-area {
    display: none;
  }
  h1 {
    @include font-bold;
    text-transform: uppercase;
    font-size: 32px;
    margin: 0 0 30px;
    text-align: center;
  }
  label {
    @include font-medium;
  }
  input {
    border: 1px solid #eaeaea;
  }
  .forgot-show {
    position: absolute;
    right: 0;
    top: 10px;
    padding: 15px;
    font-size: 12px;
    height: 100%;
  }
}
</style>
