<template>
    <div id="register" class="register-container">
      <div id="logo-box">
        <img
          src="../assets/images/ametros-learning-logo.svg"
          alt="Ametros Learning logo"
          class="register-logo"
        />
      </div>
      <div class="container register-form" v-if="!paymentSuccess">
          <div class="row">
              <div class="col">
                  <h1>Course Purchase</h1>
                  <h2 class="mb-2">You are purchasing:</h2>
                  <h2 class="mb-4"><b>{{course_name}}</b></h2>
              </div>
          </div>
          <div class="row">
              <div class="col">
                  <p>Price: {{ price | toCurrency }}</p>
                  <p>Tax: {{price * tax_rate | toCurrency }}</p>
                  <hr>
                  <p>Total: {{ final_price | toCurrency }}</p>
                  <hr>
              </div>
          </div>
          <div class="row">
              <div class="col">
                  <h3 class="credit-card-header">Credit card details</h3>
                  <p class="mb-4">Ametros Learning accepts Visa, Visa Debit, Mastercard, and American Express</p>
              </div>
          </div>
          <div class="row">
              <div class="col">
                  <form class="credit-card-form">
                      <div class="form-group">
                          <label>Enter credit card number</label>
                          <input
                              class="form-control"
                              type="text"
                              placeholder="Enter credit card number"
                              aria-label="Enter credit card number"
                              v-model="payment_form.card_number"
                              @keypress="isCreditNumber($event)"
                          >
                      </div>
                      <div class="form-group">
                          <label>Enter expiration month</label>
                          <v-select
                              class="form-control"
                              placeholder="Month" 
                              :options="returnExpirationMonths"
                              :clearable="false"
                              v-model="payment_form.expiration_month"
                          ></v-select>
                      </div>
                      <div class="form-group">
                          <label>Enter expiration year</label>
                          <v-select
                              class="form-control"
                              placeholder="Year" 
                              :options="returnExpirationYears"
                              :clearable="false"
                              v-model="payment_form.expiration_year"
                          ></v-select>
                      </div>
                      <div class="form-group">
                          <label>Enter verification code (3 digits)</label>
                          <input
                              class="form-control"
                              type="text"
                              placeholder="Enter verification code (3 digits)"
                              aria-label="Enter verification code (3 digits)"
                              v-model="payment_form.verification_code"
                              @keypress="isCVV($event)"
                          >
                      </div>
                      <div class="payment-problem" v-if="showPaymentError">
                          <p>There was a problem processing your purchase. Please check your credit card information to ensure it's accurate and try again, or try with another card.</p>
                      </div>
                      <div class="mt-5 text-end">
                          <button type="button" class="btn btn-ametros" v-on:click="purchase()">Purchase</button>
                      </div>
                  </form>
              </div>
          </div>
      </div>
      <div class="register-form success-view" v-if="paymentSuccess">
          <h1>Purchase successful</h1>
          <hr>
          <p class="mb-4">Your purchase was successful. A receipt has been emailed to you. </p>
          <div v-if="loggedIn == false">
              <p class="mb-4">You can now <router-link to="/login">login</router-link> to your Ametros Learning account.</p>
          </div>
          <div v-else>
              <p class="mb-4"><router-link to="/active">Click here to go to your Ametros dashboard.</router-link></p>
          </div>
          <div class="text-end mt-5" v-if="loggedIn == false">
              <router-link :to="{ name: 'login' }"><span class="btn btn-ametros">Login</span></router-link>
          </div>
          <p></p>
      </div>
  </div>
    
</template>

<script>
import { utilFunctionService } from "@/utils/utils.service";
import { commonConfig } from "@/utils/commonConfig";
import api from '@/services/api.js';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

export default {
    name: 'SSOPaymentForm',
    components: {
        vSelect: vSelect
    },
    props: [
        'course_name', 
        'price', 
        'tax_rate', 
        'taxable', 
        'final_price',
        'loggedIn',
        'learner_registration_id',
        'currency'
    ],
    data() {
        return {
            showPaymentError: false,
            paymentSuccess: false,
            showDashboard: '',
            showSSOPaymentForm: '',
            payment_form: {
                card_number: "",
                expiration_month: '',
                expiration_year: "",
                verification_code: ""
            },
            minCardYear: new Date().getFullYear()
        }
    },
    computed: {
        minCardMonth () {
            if (this.cardYear === this.minCardYear) return new Date().getMonth() + 1;
            return 1;
        },
        returnExpirationMonths() {
            const months = Array.from(Array(12).keys());
            const stringMonths = months.map(month => (month + 1).toString()); 
            return stringMonths.map( month => {
                if (month.length === 1) {
                    return '0' + month;
                } else {
                    return month;
                }
            })
        },
        returnExpirationYears() {
            const numArr = Array.from(Array(12).keys());
            return numArr.map(year => year + this.minCardYear);
        }
    },
    methods: {
        updateMonth(val) {
            this.payment_form.expiration_month = val;
        },
        updateYear(val) {
            this.payment_form.expiration_year = val;
        },
        isCreditNumber(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46 || this.payment_form.card_number.length >= 16) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        isCVV(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46 || this.payment_form.verification_code.length >= 3) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        purchase() {
            this.showPaymentError = false;
            const form = {
                card_number: this.payment_form.card_number,
                expiration_month: this.payment_form.expiration_month.toString(),
                expiration_year: this.payment_form.expiration_year.toString(),
                verification_code: this.payment_form.verification_code,
                payment_attempt: true,
                account_exists: false,
                price: this.price,
                tax: this.tax_rate,
                final_price: this.final_price,
                learner_registration_id: this.learner_registration_id,
                currency: this.currency
            };

            console.log(form);
            utilFunctionService.showLoaderNoCancel();
            api
                .postPurchase(form)
                .then((res) => {
                    if (res & res.data) {
                        console.log(res, "*** postPurchase res");
                    }
                    utilFunctionService.hideLoader();
                    this.showDashboard = true;
                    this.showSSOPaymentForm = false;
                    this.$emit('update:parentb', this.showDashboard);
                    this.$emit('update:parent', this.showSSOPaymentForm);
                    utilFunctionService.showSuccess(
                        commonConfig.appMessages.paymentSuccess
                    );
                    //this.$router.replace({ name: "login"});
                })
                .catch(() => {
                    utilFunctionService.hideLoader();
                    this.showPaymentError = true;
                });
        },
        testPurchase() {
              console.log("*** hit testPurchase");
              this.showDashboard = true;
              this.showSSOPaymentForm = false;
              this.$emit('update:parentb', this.showDashboard);
              this.$emit('update:parent', this.showSSOPaymentForm);
              
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../styles/variables.scss";
@import "../styles/mixin.scss";
#logo-box {
  display: flex;
}
.container {
    width: 100%;
    margin-left: 0;
    padding: 0 15px;
    margin: 0 auto;
    max-width: 1280px;
}
.register-form {
    width: 100%;
    border: 1px solid #eaeaea;
    background-color: $white;
    border-radius: 4px;
    padding: 40px 50px;
    @include media-breakpoint(md) {
      width: 500px;
      background-color: $white;
      padding: 40px 50px;
    }
    h1 {
        font-size: 28px;
        font-weight: bold;
        margin-bottom: 16px;
    }
    h2 {
        font-size: 16px;
    }
}
.credit-card-header {
  margin-bottom: 16px;
  margin-top: 16px;
}

.payment-problem {
  padding: 1em;
  background-color: rgb(244, 245, 208);
}

.form-group {
    label {
        font-weight: bold;
    }
}

.form-control::after{
    color: blue;
}

.register-container {
  @include flexProperty(center, center, column);
  padding: 20px 0;
  min-height: 100vh;
  @include media-breakpoint(sm) {
    background-color: $theme-light-background;
  }
  .register-logo {
    width: 180px;
    margin: 0 auto;
    padding-bottom: 2em;
  }
  .register-form {
    width: 100%;
    border: 1px solid #eaeaea;
    background-color: $white;
    border-radius: 4px;
    padding: 40px 50px;
    @include media-breakpoint(md) {
      width: 500px;
      background-color: $white;
      padding: 40px 50px;
    }
  }
  .left-align {
    text-align: left;
  }
  h1 {
    @include font-bold;
    //text-transform: uppercase;
    font-size: 28px;
    margin: 0 0 40px;
    text-align: left;
  }
  label {
    @include font-medium;
  }
  input {
    border: 1px solid #eaeaea;
  }
  .modal {
    max-height: 750px;
    overflow-y: auto;
  }
}

</style>