<template>
    <div id="register" class="register-container">
      <div id="logo-box">
        <img
          src="../assets/images/ametros-learning-logo.svg"
          alt="Ametros Learning logo"
          class="register-logo"
        />
      </div>
        <div class="container register-form">
            
          <terms-conditions></terms-conditions>       

            <div class="row">
                <div class="col">
                  <ValidationObserver ref="form">
                    <form class="credit-card-form">
                        <div class="form-group">
                          <div class="form-checkboxes d-inline-flex">
                            <label
                              class="custom-checkbox right"
                              name="terms"
                              >
                              <input
                                type="checkbox"
                                name="accept_terms"
                                aria-label="accept terms checkbox"
                                v-model="acceptTerms"
                                @change="getCheckboxValue"
                                v-bind:value="'accept_terms'"
                              />
                              <span for="terms" class="checkmark"></span>
                              I accept the terms and conditions
                            </label>
                          </div>
                          <div>
                            <p v-if="showSSOTermsConditionsError">You must accept the terms and conditions to register</p>
                          </div>
                        </div>
                        
                        <div class="mt-5 text-end">
                          <button 
                            type="button" 
                            class="btn btn-ametros" 
                            :disabled="!this.acceptTerms"
                            @click="continueToDash()"
                          >Continue</button>
                        </div>
                    </form>
                  </ValidationObserver>
                </div>s
            </div>
        </div>
        
    </div>
    
</template>

<script>
import { ValidationObserver } from "vee-validate";
import api from '@/services/api.js';
import 'vue-select/dist/vue-select.css';
import termsConditions from './termsConditionsSSO.vue';
//import { utilFunctionService } from "@/utils/utils.service";

export default {
    name: 'SSOTerms',
    components: {
      ValidationObserver,
      termsConditions
    },
    props: [
        'sso_learner_id',
        'showSSOTerms',
        'needs_to_pay'
    ],
    data() {
      return {
          showSSOTermsConditionsError: false,
          acceptTerms: false,
          learner_id: this.sso_learner_id,
          showTerms: this.showSSOTerms
      }
    },
    
    
    methods: {
        
      getCheckboxValue() {
        this.showSSOTermsConditionsError = false;
      },

      continueToDash() {
        api
          .acceptSSOTerms(this.learner_id)
          .then(() =>  {
            this.$store.dispatch('user/setUserSSOTerms');
            this.showTerms = false;
            if (this.needs_to_pay == true) {
              this.$emit('update:parent', this.showTerms);
              this.$emit('update:parentb', true);
              this.$emit('update:parentc', false);
            }  else {
              this.$emit('update:parentb', false);
              this.$emit('update:parentc', true);
              this.$emit('update:parent', this.showTerms);
            }
          });
      }
        
    }
}
</script>

<style lang="scss" scoped>
@import "../styles/variables.scss";
@import "../styles/mixin.scss";

#logo-box {
  display: flex;
}

.container {
    width: 100%;
    margin-left: 0;
    padding: 0 15px;
    margin: 0 auto;
    max-width: 1280px;
}
.register-form {
    width: 100%;
    border: 1px solid #eaeaea;
    background-color: $white;
    border-radius: 4px;
    padding: 40px 50px;
    //margin-top: 6em;
    @include media-breakpoint(md) {
      width: 500px;
      background-color: $white;
      padding: 40px 50px;
    }
    h1 {
        font-size: 28px;
        font-weight: bold;
        margin-bottom: 16px;
    }
    h2 {
        font-size: 16px;
    }
}
.credit-card-header {
  margin-bottom: 16px;
  margin-top: 16px;
}

.payment-problem {
  padding: 1em;
  background-color: rgb(244, 245, 208);
}

.form-group {
    label {
        font-weight: bold;
    }
}

.form-control::after{
    color: blue;
}

.register-container {
  @include flexProperty(center, center, column);
  padding: 20px 0;
  min-height: 100vh;
  @include media-breakpoint(sm) {
    background-color: $theme-light-background;
  }
  .register-logo {
    width: 180px;
    margin: 0 auto;
    padding-bottom: 2em;
  }
  .register-form {
    width: 100%;
    border: 1px solid #eaeaea;
    background-color: $white;
    border-radius: 4px;
    padding: 40px 50px;
    @include media-breakpoint(md) {
      width: 500px;
      background-color: $white;
      padding: 40px 50px;
    }
  }
  .left-align {
    text-align: left;
  }
  h1 {
    @include font-bold;
    //text-transform: uppercase;
    font-size: 28px;
    margin: 0 0 40px;
    text-align: left;
  }
  label {
    @include font-medium;
  }
  input {
    border: 1px solid #eaeaea;
  }
  .modal {
    max-height: 750px;
    overflow-y: auto;
  }
}

</style>