<template>
  <main id="login" class="login-container" v-if="!this.$route.query.id">
    <img
      src="../assets/images/ametros-learning-logo.svg"
      alt="Ametros Learning Logo"
      class="login-logo"
    />
    <div class="login-form" role="form">
      <h3>User Session Expired</h3>
      <div id="message-container">
        <p>Your Ametros user session has expired.</p>
        <div v-if="lms_link">
          <p>Please <a :href="lms_link">click on this link</a> to return to {{ organization_name }}'s platform. Once there, please log in and then click on the Ametros Learning link in your course to re-launch the Ametros Learning application.</p>
        </div>
        <div v-else>
          <p>Please return to your school or organization's learning management system, log back in, and then click on the Ametros Learning link in your course to re-launch the Ametros Learning platform.</p>
        </div>
      </div>
      
 
    </div>
  </main>
</template>
<script>
  import { mapGetters } from "vuex";
  import { utilFunctionService } from "@/utils/utils.service";

  export default {
    watch: {
              title: {
                  immediate: true,
                  handler() {
                      document.title = this.title;
                  }
              }
          },
    name: "Lti-logout-component",
    computed: {
      ...mapGetters("user", {
        userOrgLMSInfo: "getUserOrgAndLMSLink"
      }),
    },
    data() {
      return {
        
        title :'Ametros User Session Expired',
        ltiUserId: "",
        url: process.env.VUE_APP_URL,
        lms_link: '',
        organization_name: ''
      };
    },
    created() {
      const user_lms_info = this.userOrgLMSInfo;
      console.log(user_lms_info, "*** user_lms_info");
      this.lms_link = user_lms_info.lms_link;
      this.organization_name = user_lms_info.organization_name;
      setTimeout(() => {
        utilFunctionService.removeLocalStorageService("vuex");
      }, 6000);
    }
  };
</script>

<style scoped lang="scss">
@import "../styles/variables.scss";
@import "../styles/mixin.scss";
.login-container {
  @include flexProperty(center, center, column);
  padding: 20px 0;
  min-height: 100vh;
  @include media-breakpoint(sm) {
    background-color: $theme-light-background;
  }
  a {
    text-decoration: underline $blue-color solid 1px !important;
  }
  .login-logo {
    width: 180px;
    margin: 0 0 30px;
  }
  .login-form {
    width: 100%;
    border: 1px solid #eaeaea;
    background-color: $white;
    border-radius: 4px;
    padding: 40px 50px;
    @include media-breakpoint(md) {
      width: 500px;
      background-color: $white;
      padding: 40px 50px;
    }
    #message-container {
      padding-top: 2em;
      padding-bottom: 2em;
      p {
        padding-bottom: 2em;
        line-height: 1.8em;
      }
      a {
        font-weight: bold;
      }
    }
  }
  .register-area {
    display: none;
  }
  h1 {
    @include font-bold;
    text-transform: uppercase;
    font-size: 32px;
    margin: 0 0 30px;
    text-align: center;
  }
  label {
    @include font-medium;
  }
  input {
    border: 1px solid #eaeaea;
  }
  .forgot-show {
    position: absolute;
    right: 0;
    top: 10px;
    padding: 15px;
    font-size: 12px;
    height: 100%;
  }
}
</style>
